
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

import { workflowConfs } from '@/store/modules/easy-firestore/workflow-confs';
import { WORKFLOW_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIONS,
	ACTIVATED,
	AUTHORIZED_JOB_IDS_LENGTH,
	CONF_CLIENT_VERSION,
	ENVIRONMENT,
	ID,
	TARGET_DAG,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class WorkflowConfigurationsListingView extends Mixins(HeaderInfosMixin, ConfigurationCollectionMixin) {
	moduleName: string = workflowConfs.moduleName;
	overriddenColumns: string[] = ['id'];

	get routeName() {
		return WORKFLOW_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ENVIRONMENT, CONF_CLIENT_VERSION, ID, TARGET_DAG, AUTHORIZED_JOB_IDS_LENGTH, ACTIVATED, ACTIONS];
	}
}
